import axios from "axios"
import config from "../../config/";

const isLogin = async (): Promise<{ user: User, } | null> => {
    const token = localStorage.getItem('token');
    if (token) {
        try {
            const result = await axios.post(`${config.api_server_uri}/user-info`, {}, { headers: { authorization: token } });
            if (result.status === 201) {
                return {
                    user: {
                        ...result.data,
                        token,
                    },
                }
            } else {
                localStorage.removeItem('token');
                return null
            }
        } catch (err) {
            return null

        }

    } else {
        return null
    }
};
export default isLogin;