import axios from "axios";
import config from "../../config";
import { LoaderFunction, useLoaderData, redirect } from "react-router-dom"
import { useEffect } from "react";
import NotFound from "../NotFound";

const Gallery = () => {
    const gallery = useLoaderData() as ({ paint: { name: string, config: Config }, storages: { images: { filepath: string }[], label: string }[] } | undefined);
    useEffect(() => {
        if (gallery) {
            document.title = `${gallery.paint.config.title.gallery.replace(/{{name}}/, gallery.paint.name)}`;
        }
    }, []);
    return (
        <div className="w-screen ">
            {
                gallery ?
                    <div className="dark:bg-gray-800 dark:text-white relative w-screen h-screen">
                        <div className="w-screen h-16 flex justify-center items-center">
                            <h2 className="text-3xl">
                                {
                                    gallery.paint.name
                                }
                            </h2>
                            {
                                gallery.paint.config.interatif.state
                                    ?
                                    <span className="absolute right-10 bg-yellow-500 hover:bg-yellow-400 text-white font-bold py-2 px-4 rounded cursor-pointer">
                                        <a href="fsd">canvas</a>
                                    </span>
                                    :
                                    null
                            }
                        </div>
                        <div className="flex items-center justify-center h-auto dark:bg-gray-800 flex-col">
                            {
                                gallery.storages.map((storage, index) => {
                                    return <div className="flex justify-center items-center flex-col my-10" key={index}>
                                        {storage.images.length > 0 ?
                                            <>
                                                <h2 className="text-2xl w-full h-12 text-center">{storage.label === "default" ? null : storage.label}</h2>
                                                <div className="grid grid-cols-3 items-center gap-5">
                                                    {
                                                        storage.images.map((image, index) => {
                                                            return <div className="w-80 flex items-center justify-center h-80" key={index}>
                                                                <img src={`${config.api_server_uri}${image.filepath}`} alt="" className="w-full" />
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </> : null}
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    : <NotFound />
            }
        </div>
    );
};

export default Gallery;
export const loader: LoaderFunction = async ({ params }) => {
    try {
        const res = await axios.post(`${config.api_server_uri}/paint/gallery/${params.paintId}`);
        if (res.status === 200) {
            return res.data;
        } else {
            return redirect('/gallery')
        }
    } catch (err) {
        return redirect('/gallery')
    }
};
