import axios, { AxiosError } from "axios";
type res = { code: number, message: string; ImageUrl?: string, galleryName?: string, }
const PostImage = async (serverUrl: string, paintId: string, label: string, imageData: string): Promise<res> => {
    try {
        const res = await axios.post(`${serverUrl}/paint/gallery/post/${paintId}`, { label: label, image: imageData });
        return res.data;
    } catch (err: any) {
        if (err instanceof AxiosError) {
            const res = err.response;
            if (res) {
                if (res.status === 404) {
                    return {
                        code: 500,
                        message: "error to join server"
                    }
                } else {
                    return res.data;
                }

            } else {
                return {
                    code: 500,
                    message: "error to find data client side"
                }
            }
        } else {
            return {
                code: 500,
                message: "unknown error"
            }
        }
    }
};
export default PostImage;