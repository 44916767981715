import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './routes';

const App = () => {
    useEffect(() => {
        return () => { }
    }, [])

    return (
        <>
            <RouterProvider router={router} />
        </>
    );
};

export default App;
