import { LoaderFunction, redirect, useLoaderData, useNavigate } from "react-router-dom"
import React, { useEffect, useState } from "react"
import axios, { AxiosError } from "axios";
import config from "../../../config";
import isLogin from "../../../utils/isLogin";
import TPlan from "../../../@types/plan";

const BuyPlan = () => {
    const data = useLoaderData() as ({ plan: TPlan, user: User } | null);

    const plan = data?.plan;
    const navigate = useNavigate();
    const [message, setMessage] = useState<string | null>(null);
    const [price, setPrice] = useState(0);
    const [totalImage, setTotalImage] = useState(30);
    const [formPaint, setFormPaint] = useState<FormPaint>(
        {
            paintName: "",
            planId: "",
        });
    const formatter = new Intl.NumberFormat('en-US', { style: "currency", currency: "USD" });
    const submitHandle = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!data) return;
        if (!plan) return;
        const { user } = data;
        if (!user) return redirect('/login');
        const body: any = {
            paintName: formPaint.paintName,
            planId: plan._id
        };
        if (plan) {
            if (plan.questions && formPaint.questions) {
                body.questions = [];
                for (const question in formPaint.questions) {
                    body.questions.push({ label: question, value: formPaint.questions[question] })
                }
            }
            if (plan.selecter_options) {
                body.max_images = formPaint.max_images || plan.max_images_in_each_gallery;
            }
        }
        axios.post(`${config.api_server_uri}/dashboard/paint/create`, body, { headers: { "Authorization": user.token } }).then((res) => {
            if (res.status === 201) {
                const paintId = res.data.paintId;
                navigate(`/dashboard/paint/pay/${paintId}`)
            } else {
                setMessage(res.data.message);
            }
        }).catch((err: AxiosError) => {
            if (err.response) {
                const data: any = err.response.data;
                setMessage(data.message)
            } else {
                setMessage('error to join server')
            }

        });

    };

    useEffect(() => {
        if (plan) {
            if (plan.questions) {
                setFormPaint((formPaint) => {
                    formPaint.questions = {}
                    return formPaint
                });
            }
            setPrice(plan.price);
            setTotalImage(plan.max_images_in_each_gallery)
        }
    }, [plan]);
    return (
        <>
            {
                plan ? (
                    <form className="bg-white dark:bg-gray-900 w-full h-full text-2xl" onSubmit={submitHandle}>
                        <>
                            <h2 className="text-center text-5xl dark:text-white py-10"> {plan.name.toUpperCase()}</h2 >
                            <div className="w-full flex justify-center">
                                <div className="w-1/2">
                                    <div className="w-full h-14">
                                        {message ?
                                            <div className="w-full flex justify-center items-center">
                                                <div className="flex p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                                                    <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                                                    <span className="sr-only">Info</span>
                                                    <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">
                                                        {message}
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300"
                                                        aria-label="Close"
                                                        onClick={() => setMessage(null)}
                                                    >
                                                        <span className="sr-only">Close</span>
                                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                    </button>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="dark:text-white flex flex-col ml-5 w-full">
                                        <span className="text-lg mt-5">
                                            <label htmlFor="paintName">Paint name:</label>
                                        </span>
                                        <div className="m-2">
                                            <input
                                                type="text"
                                                id="paintName"
                                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                                                onChange={(event) => {
                                                    setMessage(null)
                                                    if (!event.target.value || /^[a-zA-Z0-9-]+$/.test(event.target.value)) {
                                                        setFormPaint({ ...formPaint, paintName: event.target.value });
                                                    } else {
                                                        setMessage('paint name is invalid')
                                                    }
                                                }}
                                                value={formPaint.paintName}
                                            />
                                        </div>
                                        {
                                            (plan.questions && formPaint.questions) ?
                                                <>
                                                    <span className="text-lg mt-5">Question:</span>
                                                    {plan.questions.map((question, index) => {

                                                        if (!formPaint.questions) return <><h1>error to find question an form paint</h1></>
                                                        return <div className="m-2" key={index}>
                                                            <label htmlFor={question.label} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{question.label.toUpperCase()}:</label>
                                                            <input
                                                                onChange={(event) => {
                                                                    setFormPaint({ ...formPaint, questions: { ...formPaint.questions, [question.label]: event.target.value } })
                                                                }}
                                                                value={formPaint.questions[question.label] || ""}
                                                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                                                                type="text"
                                                                id={question.label}
                                                            />
                                                        </div>
                                                    })}
                                                </>
                                                : null
                                        }
                                        {
                                            plan.selecter_options ?
                                                <>
                                                    <span className="text-lg mt-5">images max:</span>
                                                    <select
                                                        id="max-images"
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        onChange={(event) => {
                                                            if (!plan.selecter_options) return;
                                                            const CurrenTotalImage = Number(event.target.value);

                                                            setPrice(plan.price + (plan.selecter_options.range_price * ((CurrenTotalImage - plan.max_images_in_each_gallery) / plan.selecter_options.range)));
                                                            setTotalImage(CurrenTotalImage)
                                                        }}
                                                    >
                                                        {Array.from({ length: plan.selecter_options.max / plan.selecter_options.range }).map((_, index) => {
                                                            const range = plan.selecter_options?.range || 0;
                                                            return <option key={index}>{(range * (index + 1))}</option>
                                                        })}
                                                    </select>
                                                </>
                                                : null
                                        }
                                        {new Array(16).map((currentSelectImages) => {
                                            return <div>{currentSelectImages / (plan.questions ? plan.questions.length : 1)}</div>
                                        })}
                                    </div>
                                    <div className="text-white w-full m-5" >
                                        <div className="mb-2 text-3xl font-bold">
                                            price: {formatter.format(price)}
                                        </div>
                                        <div>
                                            max images in each gallery: {totalImage}
                                        </div>
                                    </div>
                                    <div className="text-white w-full flex justify-start m-5">
                                        <div className="">{plan.description}</div>
                                    </div>
                                    <button
                                        type="submit"
                                        className="ml-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    >
                                        create paint
                                    </button>
                                </div>
                            </div>
                        </>
                    </form>
                ) : (
                    <h2>connt find plans</h2>
                )
            }
        </>
    );
};

export default BuyPlan;
export const loader: LoaderFunction = async ({ params }) => {
    const isLog = await isLogin();
    if (!isLog) return redirect(`/login?planId=${params.id}`);

    const result = await axios.post(`${config.api_server_uri}/plan/${params.id}`);
    if (result.status === 200) {
        return {
            plan: result.data,
            user: isLog.user
        };
    } else {
        return null;
    }
};