import axios from "axios";
import config from "../../config";
const stripePk: (user: User) => Promise<string | null> = async (user) => {
    if (user) {
        try {
            const res = await axios.post(`${config.api_server_uri}/dashboard/stripe/pk`, {}, { headers: { "Authorization": user.token } });
            return res.data.pk
        } catch (err) {
            return null
        }
    } else {
        return null;
    }
}

export default stripePk;