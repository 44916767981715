import { LoaderFunction, redirect, useLoaderData, useParams } from "react-router-dom"
import { useEffect, useRef, useState } from "react";
import ColorPallete from "../../components/colors-pallete";
import configG from "../../config";
import Message from "../../components/message";
import Pen from "../../pen"
import Canvas from "../../components/canvas";
import BottomBar from "../../components/bottom-bar"
import isLogin from "../../utils/isLogin";
import axios from "axios";
import Claer from "../../components/widgets/clear";
import PostImage from "../../utils/post/image";
import MessageColor from "../../config/colors";
import Finish from "../../components/finish";
import LiveDrawForm from "../../components/live_draw_form";

const Paint = () => {
    const paint = useLoaderData() as ({ name: string, config: Config, id: string });
    const config = paint.config;
    const [colorIndex, setColorIndex] = useState(config.default_colors_select)
    const [toolIndex, setToolsIndex] = useState(config.default_tools_select)
    const [message, setMessage] = useState<{ color: string, content: string } | null>(null)
    const [widget, setWidget] = useState<JSX.Element | null>(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [finish, setFinish] = useState<{ image_url: string, gallery_name: string } | null>(null)
    const penRef = useRef<Pen>(new Pen(config.colors_palette[colorIndex], 8, config.use_image))
    const pen = penRef.current;
    const [liveDrawIsConnnect, setLiveDrawIsConnnect] = useState(false);
    const [liveDrawIsLoading, setLiveDrawIsLoading] = useState(config.live_draw.state);
    const [users, setUsers] = useState<{ x: number, y: number, color: string, username: string, iconPath: string }[]>([])
    // let galleryName = config.default_gallery_name;
    const { imageID } = useParams();

    pen.setMessage = setMessage;
    pen.setLiveDrawIsConnnect = setLiveDrawIsConnnect;
    pen.setUsers = setUsers;
    const onColorClick: onPalleteClick = (index, type) => {
        if (type === "color") {
            pen.setColor(config.colors_palette[index]);
            setColorIndex(index);
        } else {
            pen.undo();
        }
    };
    const setSVG = (svg: SVGSVGElement) => {
        pen.setSVG(svg);
    };
    const setCanvas = (canvas: HTMLCanvasElement) => {
        if (pen.canvas) return;
        pen.setCanvas(canvas);
        pen.setup();

        const currentTool = config.tools.find((tool) => (tool.action === "bucket" || tool.action === "eraser" || tool.action === "marker") ? tool.active : false) || config.tools[toolIndex];

        if (currentTool.action === "marker" || currentTool.action === "bucket" || currentTool.action === "eraser") {

            if (currentTool.action === "marker") {
                pen.setBrush({
                    cursor_style: currentTool.cursor_style,
                    size: currentTool.size,
                    type: "marker",
                    iconPath: currentTool.iconPath
                })
            } else if (currentTool.action === "eraser") {
                pen.setBrush({
                    cursor_style: currentTool.cursor_style,
                    size: config.eraser.size,
                    type: "eraser",
                    iconPath: currentTool.iconPath

                })
            } else {
                pen.setBrush({
                    cursor_style: currentTool.cursor_style,
                    size: NaN,
                    type: "bucket",
                    iconPath: currentTool.iconPath
                })
            }
        }

    };
    const onToolClick: OnToolClick = (tool, index) => {
        switch (tool.action) {
            case "marker":
                setToolsIndex(index)
                pen.setBrush({
                    type: "marker",
                    size: tool.size,
                    cursor_style: tool.cursor_style,
                    iconPath: tool.iconPath
                })
                break;
            case "bucket":
                setToolsIndex(index)

                pen.setBrush({
                    cursor_style: tool.cursor_style,
                    type: "bucket",
                    size: NaN,
                    iconPath: tool.iconPath

                });
                break;
            case "eraser":
                setToolsIndex(index)
                pen.setBrush({
                    cursor_style: tool.cursor_style,
                    type: "eraser",
                    iconPath: tool.iconPath,
                    size: NaN,
                });
                break;
            case "link":
                if (tool._blank) {
                    window.open(tool.href)
                } else {
                    window.location.href = tool.href;
                }
                break;
            case "addToGallery":
                if (!pen.canPost) {
                    setMessage({ color: MessageColor.error, content: "you need to draw some" })
                    return;
                }
                let galleryLable = "default"
                if (config.questions) {
                    const question = config.questions[currentQuestionIndex];
                    galleryLable = question.label;
                } else {

                    // setWidget(
                    //     <Gallery
                    //         pen={pen}
                    //         setWiget={setWidget}
                    //         html_interface={tool.html_interface}
                    //         config={config}
                    //         galleryName={galleryName}
                    //     />
                    // )
                }

                const imageData = pen.render();

                if (imageData) {
                    PostImage(config.api_server_uri, paint.id, galleryLable, imageData).then((res) => {
                        if (res.ImageUrl && res.galleryName) {
                            pen.canPost = false;
                            setFinish({
                                gallery_name: res.galleryName,
                                image_url: `${config.api_server_uri}/${res.ImageUrl}`
                            })
                        } else {
                            setMessage({
                                color: MessageColor.error,
                                content: res.message
                            })
                        }
                    });
                } else {
                    setMessage({ color: MessageColor.error, content: "error to render image" })
                }
                break;
            case "clear":
                setWidget(
                    <Claer
                        pen={pen} setWiget={setWidget}
                        html_interface={tool.html_interface}
                    />
                )
                break;
        }
    };
    const refCanvas = useRef<JSX.Element>(<canvas ref={(canvas) => canvas ? setCanvas(canvas) : false}></canvas>);
    const handleLiveDrawForm = (token: string) => {
        localStorage.setItem(paint.id, token);
        pen.setSocket(token, paint.id);
    };
    useEffect(() => {
        document.title = config.title.home.replace(/{{name}}/, paint.name);
        if (config.questions) {
            const param = new URLSearchParams(window.location.search);
            const questionIndexString = param.get('qi')
            const questionIndex = Number(questionIndexString);
            setCurrentQuestionIndex(questionIndex);
        }
        if (config.live_draw.state) {
            const token = localStorage.getItem(paint.id);
            if (token) {
                pen.setSocket(token, paint.id);
            } else {
                setLiveDrawIsLoading(false);
            }
        }
        return () => {
            pen.destroy();
        }
    }, [config.live_draw.state, config.questions, config.title.home, paint.id, paint.name, pen]);
    return <div className="w-screen h-screen">
        {config.live_draw.state && !liveDrawIsConnnect ? <LiveDrawForm handleLiveDrawForm={handleLiveDrawForm} liveDrawIsLoading={liveDrawIsLoading} paintId={paint.id} /> : null}
        {finish ? <Finish gallery_name={finish.gallery_name} image_url={finish.image_url} setFinish={setFinish} paintId={paint.id} next_question_index={config.questions ? ((config.questions.length > currentQuestionIndex + 1) ? currentQuestionIndex + 1 : undefined) : undefined} /> : null}
        {widget ? widget : null}
        {message ? <Message content={message.content} setMessage={setMessage} color={message.color} /> : null}
        {users.map(({ iconPath, username, x, y }, index) => {
            return <div key={index} className="absolute" style={{ top: `${y}px`, left: `${x}px`, zIndex: 100, }}>
                <img src={iconPath} alt="user" />
                <span>{username}</span>
            </div>
        })}
        <ColorPallete onClick={onColorClick} colorIndex={colorIndex} config={paint.config} />
        {config.questions ?
            <div className="text-center font-bold">{config.questions[currentQuestionIndex].value}</div>
            :
            null
        }
        <Canvas
            CanvasEl={refCanvas.current}
            use_image={config.use_image}
            imageID={(config.use_multi_image && !isNaN(Number(imageID))) ? Number(imageID) : config.default_image_select}
            setMessage={setMessage}
            setSVG={setSVG}
        />
        <BottomBar colorSelect={config.colors_palette[colorIndex]} use_image={config.use_image} onToolClick={onToolClick} toolIndex={toolIndex} tools={config.tools} />
    </div>
}
export default Paint;
export const loader: LoaderFunction = async ({ params, request }) => {
    await isLogin();
    try {
        const res = await axios.post(`${configG.api_server_uri}/paint/${params.id}/config`,);
        if (res.status === 200) {
            const config: Config = res.data.config;
            const clientUrl = new URL(request.url);
            const param = new URLSearchParams(clientUrl.search);

            const questionIndexString = param.get('qi')
            const questionIndex = Number(questionIndexString);
            if (!config.show_home) {
                return res.data;
            }
            if (config.questions && questionIndexString && !isNaN(questionIndex) && config.questions[questionIndex]) {
                return res.data;
            } else return redirect('?qi=0');
        } else {
            return redirect('/paint/');
        }
    } catch (err) {
        return redirect('/paint/');
    }
};