import Tools from './tools';

const BottomBar = (props: {
    onToolClick: OnToolClick,
    toolIndex: number,
    colorSelect: string,
    use_image: boolean
    tools: Tools[]
}) => {
    const { onToolClick, colorSelect, tools, toolIndex } = props;
    return (
        <div className='bottom-bar'>
            {tools.map((tools, index) => (tools.show) ? <Tools colorSelect={colorSelect} key={index} index={index} onToolClick={onToolClick} tools={tools} actived={index === toolIndex} /> : null)}
        </div>
    );
};

export default BottomBar;