import config from '../../../config';

const Undo = (props: {
    onClick: onPalleteClick
}) => {
    const { onClick } = props;

    return (
        <div onClick={() => onClick(NaN, "undo")} >
            <button>
                <img src={config.undo.icon} alt="undo icon" width={`${config.undo.size}%`} draggable="false" />
            </button>
        </div>
    );
};

export default Undo;