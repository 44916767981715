import Tools from "./tools";
import Path from "./paths";

// const api_server_host = window.location.origin;

const config: Config = {
    base_uri: new URL(document.baseURI),// l'url de base tu site
    socket_server_uri: "ws://141.94.223.85:5151/",// le serveur de socket
    api_server_uri: "http://141.94.223.85:5151",
    colors_palette: [
        '#fd990b',
        '#61d9de',
        '#ac43e3',
        '#ea2a96',
        '#7cde3b',
        '#eb212e',
        '#fff02a',
        '#2582f6',
        '#fff'
    ],
    default_colors_select: 0,// la couleur par defaut selectionner
    undo: {
        icon: Path.undo_icon,
        size: 60, // taille de icône undo en %
    },
    tools: Tools,
    default_tools_select: 2, // Si il y a aucune valeur en actif dans le tableau de tools
    canvas: {
        scale_raio: 1.2,
        default_size: {
            height: 500,
            width: 500,
        },
        color: "#FFF", // la couleur de font du canvas
        minsize: 300,
        render: {
            size: {
                width: 1000,
                height: 1000
            }
        }

    },
    eraser: {
        size: 30,
        color: "#FFF"
    },
    use_image: true,
    use_multi_image: true,
    image_paths: Path.imageURls,
    default_image_select: 0,
    svg_filter_colors: [
        "#FFFFFF",
        "#fff"
    ],
    key_input: {
        undo: 26,
        del: NaN,
    },
    intro: {
        animation_step_top: 8,
        animation_step_opacity: 0.005,
        text: "start",
        state: true,
    },
    log: false,
    credit: {
        image: Path.creditImagePath,
        opacity: 0.50,
        position: {
            x: 85,
            y: 85,
        },
        size: {
            height: 20,
            width: 20
        },
        state: true
    },
    gallery_default_post_uri: "./gallery/index.php",
    default_gallery_name: "foo",
    message_time_out: 3e3, // le temps que le message droit s'afficher en milisecond
    title: {
        home: "pop art",
        gallery: "gallery {{name}}"
    },
    interatif: {
        default_row: 4,
        row: true,
        state: true
    },
    live_draw: {
        max_users: 20,
        state: true,
    },
    show_home: true,
    questions: null,
    gallery: {
        image_line: {
            count: 3,
            state: true
        },
        live_update: true
    }
}

export default config;
