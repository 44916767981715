import { useRef, useState, useEffect } from "react";
import loadSVG from "../../utils/loadSVG"
import "../../style/components/canvas/index.css"
import paths from "../../config/paths";
import MessageColor from "../../config/colors";
import config from "../../config";
const Canvas = (props: {
    CanvasEl: JSX.Element,
    use_image: boolean,
    imageID: number
    setSVG: (svg: SVGSVGElement) => void
    setMessage: React.Dispatch<React.SetStateAction<{
        color: string;
        content: string;
    } | null>>

}) => {
    const { CanvasEl, use_image, imageID, setMessage, setSVG } = props;
    const [svgString, setSvgString] = useState<string | null>(null)
    const [loading, setLoading] = useState(true);
    const refSVGContainer = useRef<HTMLDivElement>(null);
    const init = useRef(false);

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            (async () => {
                const svgString = await loadSVG(paths.imageURls[imageID]);
                if (use_image && svgString) {
                    const DOMparser = new DOMParser().parseFromString(svgString, "image/svg+xml");
                    const svg = DOMparser.querySelector('svg');
                    if (svg) {
                        setSvgString(svg.outerHTML)
                        setLoading(false);
                    } else {
                        setMessage({ color: MessageColor.error, content: "error to find svg" })
                    }
                } else {
                    if (use_image) {
                        setMessage({
                            color: MessageColor.error,
                            content: "error to load image"
                        })
                    } else {
                        setLoading(false);
                    }
                }
            })();
        }
        if (refSVGContainer.current) {
            const svg = refSVGContainer.current.querySelector('svg');
            if (svg) {
                setSVG(svg);
            }
        }

    },)

    return (<>
        {
            !loading ?
                <div className="canvas-container">
                    {svgString ? <div className="svg-container" dangerouslySetInnerHTML={{ __html: svgString }} ref={refSVGContainer} style={{ width: config.canvas.default_size.width, height: config.canvas.default_size.height }}></div> : null}
                    {
                        CanvasEl
                    }
                </div>
                :
                null
        }
    </>
    );
};

export default Canvas;