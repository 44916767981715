import axios from "axios";
import { LoaderFunction, redirect, useLoaderData, useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';

import config from "../../../config";
import TPlan from "../../../@types/plan";
import ConfigElement from "../../../components/config/index"
import { useState, useEffect } from "react"

import isLogin from "../../../utils/isLogin";
import NotFound from "../../NotFound";

const Plan = () => {
    const data = useLoaderData() as ({ plan: TPlan, user: User, } | null);
    const planData = data?.plan;
    const [plan, setPlan] = useState(planData);
    const [submit, setSubmit] = useState(false);

    const [showConfig, setShowConfig] = useState(false);
    const [message, setMessage] = useState<string | null>(null)
    const [error, setError] = useState<string | null>(null)

    const { planId } = useParams();

    const navigate = useNavigate();
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!data) return;
        const { user } = data;

        if (!plan || !user) return setMessage('error ot find user or plan');

        try {
            const { data }: { data: { code: number, message: string } } = await axios.post(`${config.api_server_uri}/admin/plan/edit/${planId}`, plan, { headers: { authorization: user.token } });
            setSubmit(false);
            setMessage(data.message);
            window.scrollTo(0, 0);
        } catch (err) {

        }
    };
    useEffect(() => {
        (async () => {
            await isLogin();
        })();
    }, [])
    return (
        <div>
            {plan ?
                <form
                    className='h-auto w-screen dark:bg-gray-800'
                    onSubmit={handleSubmit}>
                    {message ?
                        <div className="h-10 w-full flex items-center justify-center dark:text-white pt-16 flex-col">
                            <div className="w-full sm:w-1/2">
                                <div id="alert-border-3" className="flex p-4 mb-4 text-green-800 border-t-4 border-green-300 bg-green-50 dark:text-green-400 dark:bg-gray-900 dark:border-green-800 rounded-xl">
                                    <svg className="flex-shrink-0 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                                    <div className="ml-3 text-sm font-medium">
                                        {message}
                                    </div>
                                    <button
                                        type="button"
                                        className="ml-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8 dark:bg-gray-800 dark:text-green-400 dark:hover:bg-gray-700"
                                        onClick={() => { setError(null); setMessage(null) }}
                                    >
                                        <span className="sr-only">Dismiss</span>
                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    {error ?
                        <div className="h-10 w-full flex items-center justify-center dark:text-white pt-16 flex-col">
                            <div className="w-full sm:w-1/2">
                                <div id="alert-border-3" className="flex p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 dark:text-red-400 dark:bg-red-900 dark:border-red-800 rounded-xl">
                                    <svg className="flex-shrink-0 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                                    <div className="ml-3 text-sm font-medium">
                                        {error}
                                    </div>
                                    <button
                                        type="button"
                                        className="ml-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8 dark:bg-gray-800 dark:text-green-400 dark:hover:bg-gray-700"
                                        onClick={() => { setError(null); setMessage(null) }}
                                    >
                                        <span className="sr-only">Dismiss</span>
                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    {
                        submit ?
                            <div className="h-10 fixed top-28 right-8 z-40">
                                <button
                                    type="submit"
                                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                >
                                    edit plan
                                </button>
                            </div> : null
                    }

                    <div className="absolute top-0 right-0 w-36 h-36 flex items-center justify-center z-30">
                        <div className='flex items-center justify-center '>
                            <div className="m-5">
                                <div className="flex p-2.5 transition-all duration-300 text-white rounded-xl bg-gray-800 hover:bg-gray-700 cursor-pointer" onClick={() => navigate(-1)}>
                                    <svg className="h-10 w-10" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-auto w-full flex items-center justify-center dark:text-white pt-16 flex-col">
                        <div className="inline-block align-bottom bg-white dark:bg-gray-800 text-left overflow-hidden shadow transform transition-all sm:w-1/2 rounded-none sm:rounded-lg w-full">
                            <div className="bg-white p-5 dark:bg-gray-900 ">
                                <div className="sm:flex sm:items-start ">
                                    <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                        <h3 className="text-sm leading-6 font-medium text-gray-400">name</h3>
                                        <input className="text-2xl font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                            value={plan.name}
                                            onChange={(event) => {
                                                setPlan({ ...plan, name: event.target.value })
                                                setSubmit(true);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="inline-block align-bottom bg-white dark:bg-gray-800 text-left overflow-hidden shadow transform transition-all w-full sm:w-1/2 sm:mt-3 rounded-none sm:rounded-lg">
                            <div className="bg-white p-5 dark:bg-gray-900">
                                <div className="sm:flex sm:items-start">
                                    <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                        <h3 className="text-sm leading-6 font-medium text-gray-400">config</h3>
                                        {showConfig ?
                                            <ConfigElement config={plan.config} handleConfigChangle={(config: Config) => { setPlan({ ...plan, config }) }} setSubmit={setSubmit} /> : null
                                        }
                                        <div className="w-full flex justify-center items-center">
                                            <button
                                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                                type="button"
                                                onClick={(event) => {
                                                    setShowConfig(!showConfig)
                                                }}
                                            >
                                                {showConfig ? "hide config" : "show config"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="inline-block align-bottom bg-white dark:bg-gray-800 text-left overflow-hidden shadow transform transition-all w-full sm:w-1/2 sm:mt-3 rounded-none sm:rounded-lg">
                            <div className="bg-white p-5 dark:bg-gray-900">
                                <div className="sm:flex sm:items-start">
                                    <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                        <h3 className="text-sm leading-6 font-medium text-gray-400 mb-3">options</h3>
                                        <div className="flex justify-center items-center flex-col gap-2 w-full">
                                            {plan.options.map((option, index) => {
                                                return <div className="flex justify-center items-center w-full" key={index}>
                                                    <input
                                                        className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg w-full h-10  px-2"
                                                        value={option}
                                                        onChange={
                                                            (event) => {
                                                                const options = plan.options;
                                                                if (event.target.value === "") options.splice(index, 1);
                                                                else options[index] = event.target.value;
                                                                setPlan({ ...plan, options })
                                                                setSubmit(true);
                                                            }
                                                        } />
                                                    <div
                                                        className="w-14 h-10 flex justify-center items-center bg-red-600 cursor-pointer rounded-lg ml-3 hover:bg-red-700"
                                                        onClick={() => {
                                                            const options = plan.options;
                                                            if (options.length > 1) {
                                                                options.splice(index, 1)

                                                            } else {
                                                                options[0] = "";
                                                            }
                                                            setPlan({ ...plan, options });
                                                            setSubmit(true);
                                                        }}
                                                    >
                                                        <div className="w-full flex justify-center items-center">
                                                            <svg className="w-6 h-6" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            })}


                                        </div>
                                        <div className="w-full mt-3 flex justify-end">
                                            <div className="bg-blue-700 w-10 h-10 rounded-lg" onClick={() => {
                                                const options = plan.options;
                                                options.push('');
                                                setPlan({ ...plan, options });
                                                setSubmit(true);
                                            }}>
                                                <svg className="w-10 h-10" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="inline-block align-bottom bg-white dark:bg-gray-800 text-left overflow-hidden shadow transform transition-all w-full sm:w-1/2 sm:mt-3 rounded-none sm:rounded-lg">
                            <div className="bg-white p-5 dark:bg-gray-900">
                                <div className="sm:flex sm:items-start">
                                    <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                        <h3 className="text-sm leading-6 font-medium text-gray-400 ">price</h3>
                                        <div className="flex justify-center text-2xl gap-2">
                                            $<input type="number"
                                                className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                                value={plan.price}
                                                onChange={(event) => {
                                                    setPlan({ ...plan, price: event.target.valueAsNumber })
                                                    setSubmit(true)
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="inline-block align-bottom bg-white dark:bg-gray-800 text-left overflow-hidden shadow transform transition-all w-full sm:w-1/2 sm:mt-3 rounded-none sm:rounded-lg">
                            <div className="bg-white p-5 dark:bg-gray-900">
                                <div className="sm:flex sm:items-start">
                                    <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                        <h3 className="text-sm leading-6 font-medium text-gray-400 ">description</h3>
                                        <input type="text"
                                            className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full h-10"
                                            value={plan.description}
                                            onChange={(event) => {
                                                const description = event.target.value;
                                                setPlan({ ...plan, description })
                                                setSubmit(true);
                                            }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="inline-block align-bottom bg-white dark:bg-gray-800 text-left overflow-hidden shadow transform transition-all w-full sm:w-1/2 sm:mt-3 rounded-none sm:rounded-lg">
                            <div className="bg-white p-5 dark:bg-gray-900">
                                <div className="sm:flex sm:items-start">
                                    <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                        <h3 className="text-sm leading-6 font-medium text-gray-400 ">questions</h3>
                                        {plan.questions ?
                                            <>
                                                <h3 className="text-xs leading-6 font-medium text-gray-400 ml-4">question label</h3>
                                                {plan.questions.map((question, index) => {
                                                    return <div className="my-5 flex justify-center items-center" key={index}>
                                                        <input type="text"
                                                            className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg w-full h-10  px-2"
                                                            value={question.label}
                                                            onChange={(event) => {
                                                                if (plan.questions) {
                                                                    let questions: TPlan['questions'] = plan.questions;
                                                                    if (event.target.value === "") {
                                                                        questions[index].label = event.target.value;
                                                                    } else {
                                                                        if (/^([a-zA-Z])?\w+$/.test(event.target.value)) questions[index].label = event.target.value;
                                                                    }
                                                                    setPlan({ ...plan, questions });
                                                                }
                                                            }}
                                                        />
                                                        <div
                                                            className="w-14 h-10 flex justify-center items-center bg-red-600 cursor-pointer rounded-lg ml-3 hover:bg-red-700"
                                                            onClick={() => {
                                                                let questions = plan.questions;
                                                                if (questions) {
                                                                    questions.splice(index, 1);
                                                                    if (questions.length === 0) {
                                                                        questions = null;
                                                                    }
                                                                }
                                                                setPlan({ ...plan, questions });
                                                                setSubmit(true);
                                                            }}
                                                        >
                                                            <div className="w-full flex justify-center items-center">
                                                                <svg className="w-6 h-6" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"></path>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })}
                                            </>
                                            :
                                            <div className="py-5 px-4">question field empty</div>
                                        }
                                        <div className="w-full mt-3 flex justify-end">
                                            <div className="bg-blue-700 w-10 h-10 rounded-lg" onClick={() => {
                                                let questions = plan.questions;
                                                if (!questions) {
                                                    questions = [{ label: "" }];
                                                } else {
                                                    questions.push({ label: "" });
                                                }
                                                setPlan({ ...plan, questions });
                                                setSubmit(true);
                                            }}>
                                                <svg className="w-10 h-10" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="inline-block align-bottom bg-white dark:bg-gray-800 text-left overflow-hidden shadow transform transition-all w-full sm:w-1/2 sm:mt-3 rounded-none sm:rounded-lg">
                            <div className="bg-white p-5 dark:bg-gray-900">
                                <div className="sm:flex sm:items-start">
                                    <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                        <h3 className="text-sm leading-6 font-medium text-gray-400 ">max images in each gallery</h3>
                                        <div className="flex justify-center text-2xl gap-2">
                                            <input type="number"
                                                className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                                value={plan.max_images_in_each_gallery}
                                                onChange={(event) => {
                                                    setPlan({ ...plan, max_images_in_each_gallery: event.target.valueAsNumber })
                                                    setSubmit(true)
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="inline-block align-bottom bg-white dark:bg-gray-800 text-left overflow-hidden shadow transform transition-all w-full sm:w-1/2 sm:mt-3 rounded-none sm:rounded-lg">
                            <div className="bg-white p-5 dark:bg-gray-900">
                                <div className="sm:flex sm:items-start">
                                    <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                        <h3 className="text-sm leading-6 font-medium text-gray-400 ">selecter options</h3>
                                        <div className="flex justify-start items-center">
                                            <input
                                                type="checkbox"
                                                id="selecter-options"
                                                value=""
                                                checked={!!plan.selecter_options}
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                onChange={(event) => {
                                                    let selecter_options = plan.selecter_options;

                                                    if (event.target.checked) {
                                                        if (!selecter_options) {
                                                            selecter_options = {
                                                                max: 800,
                                                                range: 50,
                                                                range_price: 500
                                                            }
                                                        }
                                                    } else selecter_options = null;
                                                    setPlan({ ...plan, selecter_options });
                                                    setSubmit(true);
                                                }}
                                            />
                                            <label htmlFor="selecter-options" className="text-xs leading-6 font-medium text-gray-400 ml-4 select-none cursor-pointer" >use</label>
                                        </div>
                                        {
                                            plan.selecter_options ?
                                                <>
                                                    <div className="bg-white dark:bg-gray-900">
                                                        <div className="sm:flex sm:items-start">
                                                            <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                                                <h3 className="text-sm leading-6 font-medium text-gray-400 ">range</h3>
                                                                <div className="flex justify-center text-2xl gap-2">
                                                                    <input type="number"
                                                                        className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                                                        value={plan.selecter_options.range}
                                                                        onChange={(event) => {
                                                                            const selecter_options = plan.selecter_options;
                                                                            if (!selecter_options) return;

                                                                            selecter_options.range = event.target.valueAsNumber;

                                                                            setPlan({ ...plan, selecter_options });
                                                                            setSubmit(true);
                                                                        }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="bg-white dark:bg-gray-900">
                                                        <div className="sm:flex sm:items-start">
                                                            <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                                                <h3 className="text-sm leading-6 font-medium text-gray-400 ">max</h3>
                                                                <div className="flex justify-center text-2xl gap-2">
                                                                    <input type="number"
                                                                        className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                                                        value={plan.selecter_options.max}
                                                                        onChange={(event) => {
                                                                            const selecter_options = plan.selecter_options;
                                                                            if (!selecter_options) return;

                                                                            selecter_options.max = event.target.valueAsNumber;

                                                                            setPlan({ ...plan, selecter_options });
                                                                            setSubmit(true);
                                                                        }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="bg-white dark:bg-gray-900">
                                                        <div className="sm:flex sm:items-start">
                                                            <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                                                <h3 className="text-sm leading-6 font-medium text-gray-400 ">range price</h3>
                                                                <div className="flex justify-center text-2xl gap-2">
                                                                    <input type="number"
                                                                        className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                                                        value={plan.selecter_options.range_price}
                                                                        onChange={(event) => {
                                                                            const selecter_options = plan.selecter_options;
                                                                            if (!selecter_options) return;

                                                                            selecter_options.range_price = event.target.valueAsNumber;
                                                                            setPlan({ ...plan, selecter_options });
                                                                            setSubmit(true);
                                                                        }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="inline-block align-bottom bg-white dark:bg-gray-800 text-left overflow-hidden shadow transform transition-all w-full sm:w-1/2 sm:mt-3 rounded-none sm:rounded-lg">
                            <div className="bg-white p-5 dark:bg-gray-900">
                                <div className="sm:flex sm:items-start">
                                    <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                        <h3 className="text-sm leading-6 font-medium text-gray-400 ">day available</h3>
                                        <input type="number"
                                            className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full h-10"
                                            value={plan.day_available}
                                            onChange={(event) => {
                                                const day_available = event.target.valueAsNumber;
                                                setPlan({ ...plan, day_available });
                                                setSubmit(true);
                                            }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="h-10"></div>
                    </div>
                </form>
                :
                <NotFound />
            }
        </div>
    );

};

export default Plan;
export const loader: LoaderFunction = async ({ params }) => {
    const isLog = await isLogin();
    if (!isLog || !isLog.user || isLog.user.role === "customer") return redirect('/login');
    try {
        const { token } = isLog.user;
        const planId = params.planId;
        const result = await axios.post(`${config.api_server_uri}/admin/plan/${planId}`, {}, { headers: { authorization: token } });

        return {
            plan: result.data,
            ...isLog
        };
    } catch (err) {
        return null;
    }
};