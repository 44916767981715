import React from 'react';
import Pen from '../../../pen';
import Html_interface from '../../../@types/config/tools/html_interface';

const Claer = (
    props: {
        pen: Pen,
        setWiget: React.Dispatch<React.SetStateAction<JSX.Element | null>>
        html_interface: Html_interface
    }
) => {
    const { setWiget, pen, html_interface } = props;
    return (
        <div className='w-screen h-screen absolute left-0 top-0 bg-white z-50 opacity-90 flex justify-center items-center'>
            <div className="top-0 right-0 absolute w-12 h-12 cursor-pointer" onClick={() => setWiget(null)}>
                <svg fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
            </div>
            <div>
                <span className='block w-full h-14 text-2xl'>{html_interface.title}</span>
                <div className="flex justify-center items-center flex-row gap-3">
                    {
                        html_interface.html_templates.map((html_template, index) => {
                            if (!html_template.show) return null;
                            const color = html_template.action === "clear" ? "blue" : "red";
                            return <div key={index}>
                                <button onClick={() => {
                                    if (html_template.action === "clear") {
                                        pen.clear();
                                        setWiget(null);
                                    } else if (html_template.action === "close") {
                                        setWiget(null)
                                    }
                                }}
                                    className={`bg-${color}-500 hover:bg-${color}-700 text-white font-bold py-2 px-4 rounded w-20`}
                                >
                                    {
                                        html_template.text
                                    }
                                </button>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default Claer;