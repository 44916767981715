class Drawn {
    private d = "";
    private setPath(d: string) {
        this.d += d;
    }
    mouseTo(x: number, y: number) {
        this.setPath(`M ${x},${y}`);
        this.setPath(`l ${0.0001},${0.0001}`);
    }
    lineTo(x: number, y: number) {
        this.setPath(`L ${x},${y}`);
    }
    get path2d() {
        return new Path2D(this.d);
    }
    get dPath() {
        return this.d;
    }
    reset() {
        this.d = "";
    }
}
export default Drawn;