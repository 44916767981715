import { LoaderFunction, NavLink, useLoaderData } from 'react-router-dom';
import NavBar from '../../components/navbar';
import isLogin from '../../utils/isLogin';

const Home = () => {
    const data = useLoaderData() as { user: User };
    const user = data?.user;
    return (
        <div className='h-screen w-full bg-gray-300 dark:bg-gray-900 text-black dark:text-white'>
            {user ? <NavBar user={user} /> : null}
            <h3 className='text-center text-3xl py-10'>
                WELCOME TO DIGIAL-MURAL
            </h3>
            <NavLink to="/plans" className="text-center text-2xl block">see plans</NavLink>
        </div >
    );
};

export default Home;
export const loader: LoaderFunction = async ({ params }) => {
    const isLog = await isLogin();
    return isLog;
};