import { useState, useEffect } from 'react';
import { LoaderFunction, NavLink, redirect, useLoaderData } from 'react-router-dom';
import isLogin from '../../utils/isLogin';
import NavBar from '../../components/navbar';
import PaintCard from "./paint-card"
import PlanCard from './plan-card';
import { useNavigate } from 'react-router-dom';

const Admin = () => {
    const { user } = useLoaderData() as { user: User, token: string };
    const hashPage = (window.location.hash.replace('#', "") || "paint") as any;

    const [page, setPages] = useState<"paint" | "plan">(hashPage);
    const navigate = useNavigate();

    useEffect(() => {
        if (hashPage === "paint" || hashPage === "plan") setPages(hashPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        navigate(`#${page}`);
    }, [page, navigate]);
    return (
        <div className='h-screen w-screen dark:bg-gray-800 bg-gray-100'>
            <NavBar user={user} />
            <div className="p-7">
                {
                    (user && user.role === "admin") ? (
                        <>
                            <div className="w-full h-14 flex justify-end items-center gap-2">
                                <NavLink className='w-28 h-12 bg-green-600 text-white rounded flex justify-center items-center' draggable="false" to="/admin/paint/create">
                                    new paint
                                </NavLink>
                                {/* <NavLink className='w-28 h-12 bg-blue-600 text-white rounded flex justify-center items-center' draggable="false" to="/plans">
                                    new plans
                                </NavLink> */}
                            </div>
                            <div >
                                <div className="w-full flex justify-start items-center flex-row gap-4 dark:text-white">
                                    <div onClick={() => setPages("plan")} className='w-auto relative cursor-pointer dark:hover:text-white/70'>
                                        Plans
                                        {page === "plan" ? <span className='w-full absolute h-0.5 bg-blue-500 -bottom-1 left-0 rounded-sm' /> : null}
                                    </div>
                                    <div onClick={() => setPages("paint")} className='w-auto relative cursor-pointer dark:hover:text-white/70'>
                                        Paint
                                        {page === "paint" ? <span className='w-full absolute h-0.5 bg-blue-500 -bottom-1 left-0 rounded-sm' /> : null}
                                    </div>
                                </div>
                                {page === "paint" ?
                                    <div>
                                        {
                                            (user.paints.length === 0) ?
                                                <div className="w-full h-auto text-white flex justify-center items-center p-16">
                                                    no application
                                                </div>
                                                : <div className='mt-5'>
                                                    {<PaintCard paints={user.paints} />}
                                                </div>
                                        }
                                    </div>
                                    :
                                    <div>
                                        {
                                            (user.plans.length === 0) ?
                                                <div className="w-full h-auto text-white flex justify-center items-center p-16">
                                                    no plan
                                                </div>
                                                : <div className='mt-5'>
                                                    {<PlanCard plans={user.plans} />}
                                                </div>
                                        }
                                    </div>}

                            </div>
                        </>
                    ) : null
                }
            </div>
        </div>
    );
};

export default Admin;
export const loader: LoaderFunction = async (data) => {
    const isLog = await isLogin();
    if (isLog && isLog.user.role === "admin") return isLog;
    else return redirect('/login');
}