import React from 'react';
import { ReactSVG } from "react-svg"
import "../../../style/components/bottom-bar/index.css"

const Tools = (props: {
    onToolClick: OnToolClick,
    index: number,
    tools: Tools,
    actived: boolean,
    colorSelect: string
}) => {
    const { onToolClick, index, tools, actived, colorSelect } = props;
    return (
        <button className={actived ? 'tool active' : 'tool'} onClick={() => onToolClick(tools, index)} >
            <div className='icon-container'>
                {
                    <ReactSVG
                        className='icon'
                        src={tools.icon}
                        beforeInjection={(svg) => {
                            svg.querySelectorAll('path').forEach((path) => {
                                svg.setAttribute('height', "40px")
                                svg.setAttribute('width', "40px")
                                if (tools.action === "bucket" && path.getAttribute('fill') === "#FF0016") {
                                    path.setAttribute('fill', colorSelect)
                                }
                            })
                        }}
                        wrapper="div"
                        renumerateIRIElements={false}
                    ></ReactSVG>
                }
            </div>
            <span>{tools.text.split('{{br}}').map((text, index) => <p key={index}>{text}<br /></p>)}</span>
        </button>
    );
};

export default Tools;